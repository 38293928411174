import React, { createContext, useState, useContext, useEffect } from "react";
import { usePointsEarnedSubscription } from "~/graphql/__generated";
import { useUser } from "~/hooks/context-hook";
import metrics from "~/utils/metrics";

type FirstPointsContextType = {
  showFirstPointsPopover: boolean;
  setShowFirstPointsPopover: (show: boolean) => void;
};

const FirstPointsContext = createContext<FirstPointsContextType | undefined>(
  undefined,
);

export const FirstPointsProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const user = useUser();
  const [showFirstPointsPopover, setShowFirstPointsPopover] = useState(false);
  const [canShowFirstPointsPopover, setCanShowFirstPointsPopover] =
    useState(false);
  const [currentPoints, setCurrentPoints] = useState(
    user?.evidencing?.points || 0,
  );

  // Check if user has 0 points to initialize the state
  useEffect(() => {
    if (user?.evidencing?.points === 0) {
      setCanShowFirstPointsPopover(true);
    }
  }, [user?.evidencing?.points]);

  // Track metrics when popover is shown
  useEffect(() => {
    if (showFirstPointsPopover) {
      metrics.track("first_points_popover_shown");
    }
  }, [showFirstPointsPopover]);

  // Subscribe to points earned across the app
  usePointsEarnedSubscription({
    skip: !user, // Skip subscription if no user is logged in
    onData: (res) => {
      const pointsEarned = res.data.data?.pointsEarned;
      if (pointsEarned) {
        setCurrentPoints((prev) => {
          const newPoints = prev + pointsEarned.updatedEvidencePoints;
          // Show first-time tooltip only if this is the first points earned
          if (canShowFirstPointsPopover && prev === 0 && newPoints > 0) {
            setShowFirstPointsPopover(true);
            setCanShowFirstPointsPopover(false);
          }
          return newPoints;
        });
      }
    },
  });

  return (
    <FirstPointsContext.Provider
      value={{ showFirstPointsPopover, setShowFirstPointsPopover }}
    >
      {children}
    </FirstPointsContext.Provider>
  );
};

export const useFirstPoints = () => {
  const context = useContext(FirstPointsContext);
  if (context === undefined) {
    throw new Error("useFirstPoints must be used within a FirstPointsProvider");
  }
  return context;
};
