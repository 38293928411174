import React from "react";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@mindstonehq/ui";
import { useFirstPoints } from "./first-points-context";
import { useUser } from "~/hooks/context-hook";

export function GlobalPointsEarnedPopover() {
  const user = useUser();
  const { showFirstPointsPopover, setShowFirstPointsPopover } =
    useFirstPoints();

  // If no user, render nothing but don't return null so parent components still render
  if (!user) return <></>;

  return (
    // NOTE: the right inset below needs to match up with the EP display alignment in the page container
    <div className="fixed top-5 right-[180px] z-50">
      <Popover
        open={showFirstPointsPopover}
        onOpenChange={setShowFirstPointsPopover}
      >
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="w-0 h-0 p-0 m-0 overflow-hidden"
          />
        </PopoverTrigger>
        <PopoverContent
          side="bottom"
          align="end"
          sideOffset={12}
          className="bg-gradient-to-br from-blue-300 via-purple-300 to-purple-400 p-[2px]"
        >
          <div className="flex items-start gap-3 p-4 max-w-[300px] bg-white dark:bg-card rounded-md">
            <div className="flex-1 space-y-2">
              <div className="flex items-start justify-between">
                <div className="flex items-center gap-2">
                  <img src="/bolt-gradient.svg" className="h-5 w-5" alt="EP" />
                  <p className="font-bold text-foreground">
                    You just earned your first EP!
                  </p>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowFirstPointsPopover(false)}
                  className="text-muted-foreground hover:text-foreground text-lg -mr-2 -mt-2"
                >
                  ×
                </Button>
              </div>
              <ul className="text-xs font-medium text-muted-foreground space-y-2">
                <li>
                  • EP (Evidence Points) track your progress and unlock the
                  assessment at the end of the program.
                </li>
                <li>
                  • They boost your leaderboard rank so you can see how you
                  compare to others.
                </li>
                <li>
                  • Everyone can see your EP, so keep building them to stand
                  out!
                </li>
              </ul>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
