import React, { useEffect } from "react";

import { useUpdateUserMetadataMutation } from "~/graphql/__generated";
import { useUser } from "~/hooks/context-hook";

export default function ProfileInitializer({
  children,
}: React.PropsWithChildren<{}>) {
  const [updateUserMetadata] = useUpdateUserMetadataMutation();

  const user = useUser();

  useEffect(() => {
    if (user) {
      const t = Intl.DateTimeFormat().resolvedOptions().timeZone;

      updateUserMetadata({
        variables: {
          timezone: t,
        },
      });
    }
  }, [user]);

  return <>{children}</>;
}
